import React from "react";
import { GeoJSON, Marker, Popup } from "react-leaflet";
import { Button } from "@material-ui/core";
import L from "leaflet";
import { NavLink } from "react-router-dom";
import iconUrl from "../image/pin24.png";
import educationUrl from "../image/education.png";
import officeUrl from "../image/office.png";
import industriesUrl from "../image/industries.png";
import infrastructureUrl from "../image/infrastructure.png";
import workshopUrl from "../image/workshop.png";
import agricultureUrl from "../image/agriculture.png";
import homestayUrl from "../image/homestay.png";
import economicUrl from "../image/economic.png";
import healthUrl from "../image/health.png";
import { BASE_URL, baseURLAPI } from "../../redux/axiosHandler";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function GeoJsonLayer(props) {
	const institutePopUp = (properties) => {
		console.log("institutePopUp", properties);
		if (!properties.officeName) {
			const baseImageUrl = `${baseURLAPI}/imageUpload/image/${properties.id}_house.jpg`;
			return (
				<div>
					<img
						src={baseImageUrl}
						alt={properties.houseNumer}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>House Number:</b> {properties.houseNumber}
					<br />
					<b>Ward Number:</b> {properties.wardNumber}
					<br />
					<b>Owner Name:</b> {properties.ownerName}
					<br />
					<b>Tole Name:</b> {properties.toleName}
					<br />
					{properties.contactNumber && (
						<span>
							<b>Contact:</b> {properties.contactNumber}
						</span>
					)}{" "}
					<br />
					<NavLink
						className="menu-link menu-toggle"
						to={`/home-survey/detail/${properties.id}`}
						target="_blank"
					>
						<Button variant="info">View Detail</Button>
					</NavLink>
				</div>
			);
		}else if(properties.officeName && properties.bankClass){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Bank Class:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.bridgeType){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>River Name:</b> {properties.riverName}
					<br />
					<b>Bridge Height:</b> {properties.bridgeHeight}
					<br />
					<b>Bridge Type:</b> {properties.bridgeType}
					<br />
					<b>Status:</b> {properties.status}
					<br />
					<b>Established At:</b> {properties.establishedAt}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.businessType){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Business Type:</b> {properties.businessType}
					<br />
					<b>Established Year:</b> {properties.establishedYear}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.historyDetail){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>History Detail:</b> {properties.historyDetail}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.principalName){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Institute Type:</b> {properties.instituteType}
					<br />
					<b>Institute Level:</b> {properties.instituteLevel}
					<br />
					<b>Established Year:</b> {properties.establishedYear}
					<br />
					<b>Principal Name:</b> {properties.principalName}
					<br />
					<b>Contact Number:</b> {properties.contactNumber}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.governmentOfficeType){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Government Office Type:</b> {properties.governmentOfficeType}
					<br />
					<b>Office Head Name:</b> {properties.officeHeadName}
					<br />
					<b>Information Officer Name:</b> {properties.informationOfficerName}
					<br />
					<b>Office Contact:</b> {properties.officeContact}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.healthOrgType){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Health Organization Type:</b> {properties.healthOrgType}
					<br />
					<b>Institute Type:</b> {properties.instituteType}
					<br />
					<b>Sponsor Name:</b> {properties.orgSponsorName}
					<br />
					<b>Contact Number:</b> {properties.orgContactNumber}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.historicalLegend){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Established Date:</b> {properties.establishedDate}
					<br />
					<b>Historical Legend:</b> {properties.historicalLegend}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.productionGoodsName){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Industry Category:</b> {properties.industryCategory}
					<br />
					<b>Director Name:</b> {properties.directorName}
					<br />
					<b>Production Goods Name:</b> {properties.productionGoodsName}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.officeHeadName){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Institution Type:</b> {properties.institutionsType}
					<br />
					<b>Office Head Name:</b> {properties.officeHeadName}
					<br />
					<b>Office Contact:</b> {properties.officeContact}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.otherName){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Other Name:</b> {properties.otherName}
					<br />
					<b>Description:</b> {properties.description}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.numberOfVisitor){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Park Speciality:</b> {properties.parkSpeciality}
					<br />
					<b>Number Of Visitor:</b> {properties.numberOfVisitor}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.presidentName){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Address:</b> {properties.address}
					<br />
					<b>President Name:</b> {properties.presidentName}
					<br />
					<b>Office Contact Number:</b> {properties.officeContactNumber}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.areaOfWork){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Area Of Work:</b> {properties.areaOfWork}
					<br />
					<b>Office Head Name:</b> {properties.officeHeadName}
					<br />
					<b>Contact Number:</b> {properties.contactNumber}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.investmentArea){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Established Date:</b> {properties.establishedDate}
					<br />
					<b>Main Work In Year:</b> {properties.mainWorkInYear}
					<br />
					<b>Investment Area:</b> {properties.investmentArea}
					<br />
					<b>Investment Amount:</b> {properties.investmentAmount}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.characteristic){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Resource Type:</b> {properties.resourceType}
					<br />
					<b>characteristic:</b> {properties.characteristic}
					<br />
					<b>Conservation Body and Name:</b> {properties.conservationBodyAndName}
					<br />
					<b>Conservation Body and Contact:</b> {properties.conservationBodyAndContact}
					<br />
					<b>Lake Area:</b> {properties.lakeArea}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.condition){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Address:</b> {properties.address}
					<br />
					<b>Condition:</b> {properties.condition}
					<br />
					<b>Manager Contact Number:</b> {properties.managerContactNumber}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		}else if(properties.officeName && properties.nearByHouseCount){
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.image}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.parentCategory}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Name:</b> {properties.parentCategory}
					<br />
					<b>Ward Number:</b> {properties.ward}
					<br />
					<b>Near By House Count:</b> {properties.nearByHouseCount}
					<br />
					<b>Condition:</b> {properties.condition}
					<br />
					<b>Google Plus Code:</b> <a
                            href={`https://plus.codes/${properties.googlePlusCode}`}
                            target="_blank"
                        >{properties.googlePlusCode}</a>
				</div>
			);
		} else {
			const baseUrl = `${BASE_URL}/api/imageUpload/instituteImage/${properties.photo}`;
			return (
				<div>
					<img
						src={baseUrl}
						alt={properties.officeName}
						width={300}
						height={200}
						style={{ objectFit: "cover" }}
						onError={(event) => {
							event.target.src = toAbsoluteUrl("/media/image/NoImageFound.jpg");
							event.onerror = null;
						}}
					/>
					<br />
					<br />
					<b>Office Name:</b> {properties.officeName}
					<br />
					<b>Ward Number:</b> {properties.wardNumber}
					<br />
					<b>Institution Type:</b> {properties.institutionsType}
					<br />
				</div>
			);
		}
	};

	return props.objPoint.features.map((eachFeature) => {
		if (eachFeature.geometry.type === "Point") {
			const properties = eachFeature.properties;
			const eachCoordinates = eachFeature.geometry.coordinates;
			var pointerIcon = L.Icon.extend({
				options: {
					iconAnchor: [12, 24],
				},
			});

			var icon;
			if (properties.institutionsType === "education") {
				icon = new pointerIcon({
					iconUrl: educationUrl,
				});
			} else if (
				properties.institutionsType === "gov_office" ||
				properties.institutionsType === "offices" ||
				properties.institutionsType === "palika_related_office"
			) {
				icon = new pointerIcon({ iconUrl: officeUrl });
			} else if (properties.institutionsType === "industries") {
				icon = new pointerIcon({ iconUrl: industriesUrl });
			} else if (properties.institutionsType === "education") {
				icon = new pointerIcon({ iconUrl: agricultureUrl });
			} else if (properties.institutionsType === "economic") {
				icon = new pointerIcon({ iconUrl: economicUrl });
			} else if (properties.institutionsType === "forest") {
				icon = new pointerIcon({ iconUrl: homestayUrl });
			} else if (properties.institutionsType === "cultural_heritage") {
				icon = new pointerIcon({ iconUrl: workshopUrl });
			} else if (
				properties.institutionsType === "health" ||
				properties.institutionsType === "public_places"
			) {
				icon = new pointerIcon({ iconUrl: healthUrl });
			} else if (properties.institutionsType === "infrastructure") {
				icon = new pointerIcon({ iconUrl: infrastructureUrl });
			} else {
				icon = new pointerIcon({ iconUrl: iconUrl });
			}

			return (
				<Marker position={[eachCoordinates[1], eachCoordinates[0]]} icon={icon}>
					<Popup>{institutePopUp(properties)}</Popup>
				</Marker>
			);
		}
	});
}
