import React, { Component } from "react";
import { Button, Card, CardGroup, Modal } from "react-bootstrap";
import { Checkbox } from "@material-ui/core";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import L from "leaflet";
import { MapContainer, TileLayer, LayersControl, GeoJSON } from "react-leaflet";
import hash from "object-hash";
import Axios from "../../redux/axiosHandlerGeo";
import GeoJsonLayer from "./GeoJsonLayer";
import kanchanpur from "../../geojson/kanchanpur.json";
import { infastructure } from "../../variable/global";
// import kanchanpur from "../../geojson/bedkot.json";
import "leaflet/dist/leaflet.css";
import "../../assets/css/homePageDescription.scss";
import { connect } from "react-redux";

class EmergencyContactModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: [],
        };
    }
    componentDidMount() {
        Axios.get("/api/emergencyNumber/all")
            .then((res) => {
                this.setState({
                    sections: res.data,
                });
            })
            .catch((err) => {
                console.log("dataSurvey err", err);
            });

        // let sections = [
        //     {
        //         title: "सबै सम्पर्क नम्बरहरु",
        //         englishTitle: "All Contact Numbers",
        //         numbers: [
        //             {
        //                 title: "एम्बुलेन्स",
        //                 englishTitle: "Ambulance",
        //                 value: "९८६८०११६००",
        //                 englishValue: "9868011600",
        //             },
        //             {
        //                 title: "दमकल",
        //                 englishTitle: "Fire Brigade",
        //                 value: "१०१, ९८५८०५०६०७",
        //                 englishValue: "101, 9858050607",
        //             },
        //             {
        //                 title: "जिल्ला प्रहरी",
        //                 englishTitle: "District Police Office",
        //                 value: "१००",
        //                 englishValue: "100",
        //             },
        //         ],
        //     },
        // ];
        // this.setState({ sections });
    }

    renderContacts() {
        return (
            <>
                {this.state.sections.map((section, secIndex) => {
                    return (
                        <div key={secIndex}>
                            {/* {section.title ? (
                                <>
                                    <h3 className="font-weight-bolder mb-4 mt-4">
                                        {this.props.selectedLanguage == "nepali"
                                            ? section.title
                                            : section.englishTitle}
                                    </h3>
                                </>
                            ) : null} */}
                            <div className="row">
                                {/* {section.numbers.map((number, numIndex) => { */}
                                {/* return ( */}
                                <div
                                    className="col-lg-6 d-flex flex-wrap mb-4"
                                    key={secIndex}
                                >
                                    <h5 className="mr-2" id="modal-bold">
                                        {this.props.selectedLanguage == "nepali"
                                            ? section.name
                                            : section.nameEnglish}
                                        :{" "}
                                    </h5>
                                    <h5 className="text-primary font-weight-bold">
                                        {this.props.selectedLanguage == "nepali"
                                            ? section.contactNumber
                                            : section.contactNumber}
                                    </h5>
                                </div>
                                {/* ); */}
                                {/* })} */}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }
    render() {
        return (
            <Modal
                {...this.props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                closeButton
            >
                <Modal.Body className="p-md-15">
                    <div className="d-flex justify-content-between mb-6">
                        <h1 className="font-weight-bolder text-danger">
                            <i className="icon-emergency text-danger display-4"></i>{" "}
                            {this.props.selectedLanguage == "nepali"
                                ? "आपतकालीन सम्पर्क"
                                : "Emergency Contact"}
                        </h1>
                        <i
                            className="fa fa-times"
                            onClick={this.props.onHide}
                        ></i>
                    </div>
                    {this.renderContacts()}
                </Modal.Body>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedLanguage: state.ward.selectedLanguage,
});
export default connect(mapStateToProps)(EmergencyContactModal);
