import React, { useEffect, useMemo, useState } from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
    Button,
    Dropdown,
    DropdownButton,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import * as actions from "../../../../app/modules/Hamropalika/_redux/WardRedux";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { BASE_URL } from "../../../../redux/axiosHandler";
export function Topbar() {
    const uiService = useHtmlClassService();
    const dispatch = useDispatch();
    const layoutProps = useMemo(() => {
        return {
            viewSearchDisplay: objectPath.get(
                uiService.config,
                "extras.search.display"
            ),
            viewNotificationsDisplay: objectPath.get(
                uiService.config,
                "extras.notifications.display"
            ),
            viewQuickActionsDisplay: objectPath.get(
                uiService.config,
                "extras.quick-actions.display"
            ),
            viewCartDisplay: objectPath.get(
                uiService.config,
                "extras.cart.display"
            ),
            viewQuickPanelDisplay: objectPath.get(
                uiService.config,
                "extras.quick-panel.display"
            ),
            viewLanguagesDisplay: objectPath.get(
                uiService.config,
                "extras.languages.display"
            ),
            viewUserDisplay: objectPath.get(
                uiService.config,
                "extras.user.display"
            ),
        };
    }, [uiService]);
    const getHeaderLogo = () => {
        let result = "nepal_gov.png";
        if (
            layoutProps.headerSelfTheme &&
            layoutProps.headerSelfTheme !== "dark"
        ) {
            result = "nepal_gov.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    };
    useEffect(() => {
        dispatch(actions.selectLanguage("nepali"));
        dispatch(actions.fetchPopulationDetails());
        dispatch(actions.fetchHouseOwnerGender());
        dispatch(actions.fetchReligion());
        dispatch(actions.fetchMotherTongue());
        dispatch(actions.fetchIsSchoolGoing());
        dispatch(actions.fetchMarital());
        dispatch(actions.fetchAgricultureProductionOrNot());
        dispatch(actions.fetchLivestockRaising());
        dispatch(actions.fetchAnimalCount());
    }, [dispatch]);
    const [selectedWard, setSelectedWard] = useState("गाउँपालिका");
    const [selectedLanguage, setSelectedLanguage] = useState("नेपाली");
    const [langSelectTitle, setlangSelectTitle] = useState("भाषा छान्नुहोस");
    const [wardSelectTitle, setwardSelectTitle] = useState("वडा छान्नुहोस");

    const { currentState } = useSelector(
        (state) => ({ currentState: state.ward }),
        shallowEqual
    );
    const languages = [
        {
            val: "English",
            url: "/media/hamropalika/logos/us-flag.svg",
        },
        {
            val: "नेपाली",
            url: "/media/hamropalika/logos/nepal-flag.svg",
        },
    ];
    const handleLangSelect = (e) => {
        const bodyTags = document.getElementsByTagName("body");
        if (e == "English") {
            if (bodyTags.length) {
                bodyTags[0].style.fontFamily =
                    '"Roboto", "Helvetica", "Arial", sans-serif';
            }
            dispatch(actions.selectLanguage("english"));
            if (selectedWard == "गाउँपालिका") {
                setSelectedWard("Rural Municipality");
            } else {
                let currSelWardVal = selectedWard.split(" ");
                setSelectedWard(`Ward ${currSelWardVal[1]}`);
            }
            setlangSelectTitle("Select language");
            setwardSelectTitle("Select ward");
            setSelectedLanguage(e);
        } else {
            if (bodyTags.length) {
                bodyTags[0].style.fontFamily = 'Kalimati-Regular, "sans-serif"';
            }
            dispatch(actions.selectLanguage("nepali"));
            if (selectedWard == "Rural Municipality") {
                setSelectedWard("गाउँपालिका");
            } else {
                let currSelWardVal = selectedWard.split(" ");
                setSelectedWard(`वडा ${currSelWardVal[1]}`);
            }
            setlangSelectTitle("भाषा छान्नुहोस");
            setwardSelectTitle("वडा छान्नुहोस");
            setSelectedLanguage(e);
        }
        // setSelectedLanguage(e)
    };
    const handleSelect = (e) => {
        if (currentState.selectedLanguage == "nepali") {
            if (parseInt(e)) {
                setSelectedWard(`वडा ${e}`);
            } else {
                setSelectedWard(e);
            }
        } else {
            if (parseInt(e)) {
                setSelectedWard(`Ward ${e}`);
            } else {
                setSelectedWard("Rural Municipality");
            }
        }
        //console.log(e);
        dispatch(actions.fetchWardDetails(e));
    };
    const getDropDownClass = (wardId) => {
        const wardVal = typeof wardId == "string" ? wardId : `वडा ${wardId}`;
        if (selectedWard == wardVal) {
            return "bg-light-primary";
        }
    };
    const getLangDropDownClass = (lang) => {
        if (selectedLanguage == lang) {
            return "bg-light-primary";
        }
    };
    const getWardListItem = (wardId) => {
        if (currentState.selectedLanguage == "nepali") {
            return typeof wardId == "string" ? "गाउँपालिका" : `वडा ${wardId}`;
        }
        return typeof wardId == "string"
            ? "Rural Municipality"
            : `Ward ${wardId}`;
    };
    const getTopbarLogo = () => {
        if (currentState.selectedLanguage == "nepali") {
            return (
                <>
                    <h3 className="text-danger text-left">जोरायल गाउँपालिका</h3>
                    <p className="text-danger text-left">
                        <strong>सुदूरपश्चिम प्रदेश, नेपाल</strong>
                    </p>
                    <p id="topbar-text" className="text-primary text-left">
                        <strong>समृद्धि र सुशासन</strong>
                    </p>
                </>
            );
        }
        return (
            <>
                <h3 className="text-danger text-left">
                    Jorayal Rural Rural Municipality
                </h3>
                <p className="text-danger text-left">
                    <strong>Sudurpashchim Pradesh, Nepal</strong>
                </p>
                <p id="topbar-text" className="text-primary text-left">
                    <strong>Prosperity and Good Governance</strong>
                </p>
            </>
        );
    };
    const getLangSelectClass = (val) => {
        console.log(selectedLanguage, val);
        let classVal = "";
        val !== "English"
            ? (classVal = "nepali-lang-select ")
            : (classVal = "");
        val !== selectedLanguage
            ? (classVal += "non-selected-lang-color")
            : (classVal += "");
        return classVal;
    };
    // const getSelectedLang = () => {
    //   return (
    //   )
    // }
    return (
        <div className="topbar d-flex justify-content-between align-items-center">
            {/* <div className="header-logo d-flex align-items-center justify-content-start">
        <Link to="/">
          <img alt="logo" src={getHeaderLogo()} />
        </Link>
      </div> */}

            <NavLink to={`/home`}>
                <div className="d-flex align-items-center">
                    <img
                        className="hamropalika-logo mr-4"
                        src={toAbsoluteUrl(`/media/logos/nepal_gov.png`)}
                        alt=""
                    />
                    <div className="d-flex justify-content-center flex-column">
                        {getTopbarLogo()}
                    </div>
                </div>
            </NavLink>

            <div className="d-flex">
                {/* <div className="d-flex mr-2">
{
languages.map((language, index) => {
	return (
		<a href="javascript:void()" onClick={() => handleLangSelect(language.val)} className={getLangSelectClass(language.val)}>{language.val}{index < languages.length - 1 ? "|" : ""}</a>
	)
})
}
</div> */}
                <div className="d-flex flex-column justify-content-center mr-5 align-items-start">
                    {/* <p>
<strong>{wardSelectTitle}</strong>
</p> */}
                    <div className="mb-4">
                    <Link to="/MapDetailPage" target="_blank" style={{marginRight:"15px"}}>
                            {/* <button className="btn btn-outline-primary"> */}
                            <i class="icon icon-area-with-pins" style={{marginRight:"2px"}}></i>
                                {currentState.selectedLanguage == "nepali"
                                    ? "स्रोत नक्सा"
                                    : "Resource Map"}
							{/* </button> */}
						</Link>
                        {languages.map((language, index) => {
                            return (
                                <a
                                    href="javascript:void()"
                                    onClick={() =>
                                        handleLangSelect(language.val)
                                    }
                                    className={`${getLangSelectClass(
                                        language.val
                                    )}`}
                                >
                                    {language.val}
                                    {index < languages.length - 1 ? " | " : ""}
                                </a>
                            );
                        })}
                    </div>
                    <div className="d-flex align-items-center">
                        <DropdownButton
                            id="ward-selector"
                            title={selectedWard}
                            size="lg"
                            block
                            onSelect={handleSelect}
                            className="mr-2"
                        >
                            {currentState.entities.ward.map((ward, index) => {
                                return (
                                    <Dropdown.Item
                                        as="button"
                                        key={index}
                                        eventKey={ward.ward}
                                        className={getDropDownClass(ward.ward)}
                                    >
                                        {getWardListItem(ward.ward)}
                                    </Dropdown.Item>
                                );
                            })}
                        </DropdownButton>

                        <a href={BASE_URL} target="_blank">
                            <h5 className="text-primary">
                                {currentState.selectedLanguage == "nepali"
                                    ? "लग-इन"
                                    : "Login"}
                            </h5>
                        </a>
                    </div>
                </div>
                {/* <a href={BASE_URL} target="_blank">
<Button variant="link text-success">
<h5>
	{currentState.selectedLanguage == "nepali" ? "लग-इन" : "Login"}
</h5>
</Button>
</a> */}
            </div>
            {/*       
      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />}

      {layoutProps.viewCartDisplay && <MyCartDropdown />}

      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "//media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />}

      {layoutProps.viewUserDisplay && <QuickUserToggler />} */}
        </div>
    );
}
